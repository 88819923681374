
import { Api } from '@/api';
import { AppRoute } from '@/router/routes';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'forgot-password',
})
export default class ForgotPassword extends Vue {
  model = {
    email: '',
  };

  isLoading = false;

  async onSubmit() {
    if (!this.model.email) {
      return;
    }

    this.isLoading = true;
    try {
      await Api.forgotPassword(this.model);
      this.$notify.success('Code with password reset instructions has been sent to your email.');
    } catch (e) {
      console.log(e);
      this.$notify.error({
        title: 'Code reset failed',
        description: 'Please try again or contact administrator.',
      });
    } finally {
      this.isLoading = false;
    }
  }
}
