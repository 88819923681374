
import { ChainSettings, SelectOption } from '@/types';
import { BotDomain } from 'ignite360-core';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ChainFeaturesCheckboxGroup from '@/components/chain/ChainFeaturesCheckboxGroup.vue';

@Component({
  name: 'chain-form',
  components: { ChainFeaturesCheckboxGroup },
})
export default class ChainForm extends Vue {
  @Prop({ required: true, type: Object })
  readonly value!: ChainSettings;

  /* eslint-disable @typescript-eslint/no-explicit-any */
  onInput(key: keyof ChainSettings, value: any) {
    this.$emit('input', { ...this.value, [key]: value });
  }

  get domainSelectOptions(): SelectOption[] {
    return [
      { value: BotDomain.RealEstate, label: 'Real Estate', disabled: false },
      { value: BotDomain.Restaurant, label: 'Restaurant', disabled: false },
    ];
  }
}
