
import BotAvatar from '@/components/bot/BotAvatar.vue';
import { SelectOption } from '@/types';
import { Chain } from 'ignite360-core';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'chain-select',
  components: { BotAvatar },
})
export default class ChainSelect extends Vue {
  @Prop({ required: false, type: String })
  readonly value!: Chain['id'];

  @Prop({ required: false, type: String, default: () => null })
  readonly id!: string | null;

  @Prop({ required: false, type: [String, Array], default: () => '' })
  readonly exclude!: Chain['id'] | Chain['id'][];

  get chains(): Chain[] {
    return this.$store.state.chains.chains;
  }

  get filteredChains(): Chain[] {
    return Array.isArray(this.exclude) && this.exclude.length
      ? this.chains.filter((chain) => !this.exclude.includes(chain.id))
      : typeof this.exclude === 'string' && this.exclude
      ? this.chains.filter((chain) => chain.id !== this.exclude)
      : this.chains;
  }

  get sortedChains(): Chain[] {
    return this.filteredChains.slice().sort((a, b) => a.name.localeCompare(b.name));
  }

  get selectedChain(): Chain | undefined {
    return this.filteredChains.find((chain) => chain.id === this.value);
  }

  get selectOptions(): SelectOption[] {
    return this.sortedChains.map((chain) => ({
      label: chain.name,
      value: chain.id,
      disabled: false,
    }));
  }
}
