
import { NotificationData, NotificationType } from '@/types';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'app-notification',
})
export default class AppNotification extends Vue {
  @Prop({ required: true, type: Object })
  readonly notification!: NotificationData;

  get notificationTypeIconMap(): Record<NotificationType, string> {
    return {
      [NotificationType.Success]: 'M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z',
      [NotificationType.Error]:
        'M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z',
      [NotificationType.Warning]: 'M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z',
    };
  }
}
