import store, { RootState } from '@/store';
import { Module } from 'vuex';
import {
  GetLastConversationsDto,
  InboxLog,
  InboxLogUser,
  Interaction,
  SelectUserConversationsDto,
} from '@jovotech/inbox-core';
import { InboxApi } from '@/api/InboxApi';
import { CheckedConversation } from 'ignite360-core';

export interface InboxModuleState {
  conversations: Interaction[];
  selectedUserConversations: InboxLog[];
  selectedInboxLog: InboxLog | null;
  selectedInteraction: Interaction | null;
  checkedConversations: Record<string, Date>;

  nameMap: Record<
    string,
    {
      name: string;
      image?: string;
    }
  >;
}

export const InboxModule: Module<InboxModuleState, RootState> = {
  namespaced: true,
  state: {
    conversations: [],
    selectedUserConversations: [],
    selectedInboxLog: null,
    selectedInteraction: null,
    checkedConversations: {},

    nameMap: {},
  },

  actions: {
    async fetchConversations(context, getLastConversationsDto: GetLastConversationsDto) {
      const result = await InboxApi.loadConversations(getLastConversationsDto);
      context.commit('setConversations', result);
    },
    async fetchCheckedConversations(context, data: { projectId: string }) {
      const result = await InboxApi.loadCheckedConversations(data.projectId);
      context.commit('setCheckedConversations', result);
    },

    async clearConversations(context) {
      context.commit('clearConversations');
    },
    async fetchUserConversations(context, selectUserConversationsDto: SelectUserConversationsDto) {
      const result = await InboxApi.getUserConversations(selectUserConversationsDto);

      context.commit('setSelectedUserConversations', result.logs);
    },
    async appendUserConversations(context, selectUserConversationsDto: SelectUserConversationsDto) {
      const result = await InboxApi.getUserConversations(selectUserConversationsDto);
      const selectedUserConversations = context.state.selectedUserConversations;

      context.commit('setSelectedUserConversations', selectedUserConversations.concat(result.logs));
    },
    async appendLastConversations(context, getLastConversationsDto: GetLastConversationsDto) {
      const result = await InboxApi.loadConversations(getLastConversationsDto);
      const conversations = context.state.conversations;

      context.commit('setConversations', conversations.concat(result));
    },
    async selectInboxLog(context, inboxLog: InboxLog) {
      context.commit('selectInboxLog', inboxLog);
    },

    async selectInteraction(context, interaction: Interaction) {
      context.commit('selectInteraction', interaction);
    },

    async buildProjectUsersMap(context, projectId: string) {
      const result = await InboxApi.getInboxProjectUsers(projectId);

      const nameMap: Record<
        string,
        {
          name: string;
          image?: string;
        }
      > = {};

      result.data.forEach((item: InboxLogUser) => {
        nameMap[item.platformUserId] = {
          name: item.name,
          image: item.image,
        };
      });

      return { nameMap };
    },
  },
  mutations: {
    setConversations(state, conversations: Interaction[]) {
      state.conversations = conversations;
    },

    setCheckedConversations(state, conversations: CheckedConversation[]) {
      const checkedConversations: Record<string, Date> = {};
      for (const conversation of conversations) {
        checkedConversations[conversation.botId + '-' + conversation.inboxUserId] = new Date(
          conversation.lastUpdatedAt,
        );
      }

      state.checkedConversations = checkedConversations;
    },

    clearConversations(state) {
      state.conversations = [];
    },

    setSelectedUserConversations(state, selectedUserConversations: InboxLog[]) {
      state.selectedUserConversations = selectedUserConversations;
    },

    clearSelectedConversation(state) {
      state.selectedUserConversations = [];
    },

    selectInboxLog(state, inboxLog: InboxLog) {
      state.selectedInboxLog = inboxLog;
    },

    selectInteraction(state, interaction: Interaction) {
      state.selectedInteraction = interaction;
    },
  },
};
