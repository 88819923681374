
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'app-list',
})
export default class AppList<T extends any> extends Vue {
  @Prop({ required: true, type: Array })
  readonly items!: T[];

  @Prop({ required: true, type: Function })
  readonly getItemKeyFn!: (item: T, index: number) => string;
}
