
import { NotificationData } from '@/types';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'notification-container',
})
export default class NotificationContainer extends Vue {
  get notifications(): NotificationData[] {
    return this.$store.state.notifications.notifications;
  }

  removeNotification(notification: NotificationData) {
    this.$store.commit('notifications/remove', notification.id);
  }
}
