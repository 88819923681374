
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'app-card',
})
export default class AppCard extends Vue {
  @Prop({ required: false, type: String, default: '' })
  readonly header!: string;

  @Prop({ required: false, type: [Array, String], default: () => [] })
  readonly headerClass!: string | string[];

  @Prop({ required: false, type: [Array, String], default: () => [] })
  readonly bodyClass!: string | string[];

  @Prop({ required: false, type: [Array, String], default: () => [] })
  readonly footerClass!: string | string[];

  @Prop({ required: false, type: Boolean, default: false })
  readonly scrollable!: boolean;

  get mergedBodyClass() {
    return [
      this.scrollable ? 'overflow-y-auto scrollbar' : '',
      ...(typeof this.bodyClass === 'string' ? [this.bodyClass] : this.bodyClass),
    ];
  }
}
