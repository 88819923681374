
import DefaultNavbarDesktop from '@/components/default-layout/DefaultNavbarDesktop.vue';
import DefaultNavbarMobile from '@/components/default-layout/DefaultNavbarMobile.vue';
import { Component, Vue } from 'vue-property-decorator';
import { AppRoute } from '@/router/routes';
import { Api } from '@/api';
import { AuthMixin } from '@/mixins/AuthMixin';
import { mixins } from 'vue-class-component';

@Component({
  name: 'default-navbar',
  components: { DefaultNavbarDesktop, DefaultNavbarMobile },
})
export default class DefaultNavbar extends mixins(AuthMixin) {
  isMobileMenuVisible = false;
  isProfileDropdownVisible = false;

  async handleSignOut() {
    try {
      await Api.logout();
      await this.$store.dispatch('auth/logout');
      await this.$router.replace({ name: AppRoute.Login });
      this.$notify.success({
        title: 'Logout successful',
      });
    } catch (e) {
      console.log(e);
    }
  }

  onClick(event: MouseEvent) {
    if (
      event.target &&
      (this.$refs.button as HTMLButtonElement | undefined)?.contains(event.target as HTMLElement)
    ) {
      return;
    }
    if (!this.isProfileDropdownVisible || !event.target || !this.$refs.popover) return;
    if (!(this.$refs.popover as HTMLElement).contains(event.target as HTMLElement)) {
      this.close();
    }
  }

  toggleDropdown() {
    this.isProfileDropdownVisible ? this.close() : this.open();
  }

  close() {
    this.isProfileDropdownVisible = false;
    document.body.removeEventListener('click', this.onClick);
  }

  open() {
    this.isProfileDropdownVisible = true;
    document.body.addEventListener('click', this.onClick);
  }
}
