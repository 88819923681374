
import DefaultSidebarLink from '@/components/default-layout/DefaultSidebarLink.vue';
import { AppRoute } from '@/router/routes';
import { Chain, SupportedFeature } from 'ignite360-core';
import { Component, Vue } from 'vue-property-decorator';
import ChainSelect from '@/components/chain/ChainSelect.vue';

@Component({
  name: 'chain-sidebar',
  components: { ChainSelect, DefaultSidebarLink },
})
export default class ChainSidebar extends Vue {
  get chain(): Chain | undefined {
    return this.$store.state.chains.chains.find(
      (chain: Chain) => chain.id === this.$route.params.id,
    );
  }

  get announcementFeatureEnabled(): boolean {
    return this.chain?.selectedFeatures?.includes(SupportedFeature.Announcement) ?? false;
  }

  onChainSelect(id: Chain['id']) {
    // TODO update logic for domain-change
    if (this.$route.name?.startsWith('chain.')) {
      const selectedChain = this.$store.state.chains.chains.find((chain: Chain) => chain.id === id);
      if (!selectedChain) {
        return;
      }
      const routeName = this.$route.name;
      this.$router.push({ name: routeName, params: { ...this.$route.params, id } });
    } else {
      this.$router.push({ name: AppRoute.ChainOverview, params: { id } });
    }
  }
}
