import { Api, INBOX_BASE_URL } from '@/api/index';
import axios, { AxiosRequestConfig } from 'axios';
import {
  GetInboxLogUserDto,
  GetLastConversationsDto,
  InboxLogUser,
  Interaction,
  Project as InboxProject,
  SelectUserConversationsDto,
  UpdateInboxLogUserDto,
  UserConversationsResponse,
} from '@jovotech/inbox-core';
import { CheckedConversation } from 'ignite360-core';

export class InboxApi {
  static async loadInboxProject(): Promise<InboxProject | undefined> {
    const config = Api.finalizeConfig({
      method: 'GET',
      url: INBOX_BASE_URL + '/project',
    });

    const result = await axios.request<InboxProject>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
  }

  static async loadConversations(dto: GetLastConversationsDto): Promise<Interaction[]> {
    const config = Api.finalizeConfig({
      method: 'POST',
      url: INBOX_BASE_URL + '/conversations',
      data: dto,
    });

    const result = await axios.request<Interaction[]>(config);
    if (result.status === 201 && result.data) {
      return result.data;
    }
    return [];
  }

  static async loadUncheckedConversations(botId: string): Promise<Interaction[]> {
    const config = Api.finalizeConfig({
      method: 'GET',
      url: INBOX_BASE_URL + `/unchecked-conversations/${botId}`,
    });

    const result = await axios.request<Interaction[]>(config);
    if (result.status === 201 && result.data) {
      return result.data;
    }
    return [];
  }

  static async loadCheckedConversations(botId: string): Promise<CheckedConversation[]> {
    const config = Api.finalizeConfig({
      method: 'GET',
      url: INBOX_BASE_URL + `/checked-conversations/${botId}`,
    });

    const result = await axios.request<CheckedConversation[]>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    return [];
  }

  static async markConversationAsChecked(botId: string, inboxUserId: string) {
    const config = Api.finalizeConfig({
      method: 'POST',
      url: INBOX_BASE_URL + `/conversations/check`,
      data: {
        inboxUserId,
        projectId: botId,
      },
    });

    const result = await axios.request(config);
    if (result.status === 201) {
      return;
    }
    throw new Error('Could not mark conversation as checked.');
  }

  static async getUserConversations(selectUserConversationsDto: SelectUserConversationsDto) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${INBOX_BASE_URL}/user/conversation`,
      data: selectUserConversationsDto,
    };
    const result = await axios.request<UserConversationsResponse>(config);
    if (result.status === 201 && result.data) {
      return result.data;
    }
    throw new Error('Could not load last conversations.');
  }

  static async getInboxProjectUsers(projectId: string) {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `${INBOX_BASE_URL}/inboxloguser/${projectId}`,
    };
    return await axios.request<InboxLogUser[]>(config);
  }

  static async updateInboxLogUser(updateInboxLogUserDto: UpdateInboxLogUserDto) {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      url: `${INBOX_BASE_URL}/inboxloguser`,
      data: updateInboxLogUserDto,
    };

    return await axios.request<InboxLogUser>(config);
  }

  static async getInboxLogUser(getInboxLogUserDto: GetInboxLogUserDto) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${INBOX_BASE_URL}/inboxloguser`,
      data: getInboxLogUserDto,
    };
    return await axios.request<InboxLogUser>(config);
  }
}
