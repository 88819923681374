
import BotAvatar from '@/components/bot/BotAvatar.vue';
import { Chain } from 'ignite360-core';
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { AuthMixin } from '@/mixins/AuthMixin';

@Component({
  name: 'chain-list-card',
  components: { BotAvatar },
})
export default class ChainListCard extends mixins(AuthMixin) {
  get chains(): Chain[] {
    return this.$store.state.chains.chains;
  }

  // sort by last updated desc
  sortChains = (a: Chain, b: Chain) => {
    const lastUpdatedA = new Date(a.lastUpdatedAt).getTime();
    const lastUpdatedB = new Date(b.lastUpdatedAt).getTime();

    if (lastUpdatedA > lastUpdatedB) {
      return -1;
    } else if (lastUpdatedA < lastUpdatedB) {
      return 1;
    }
    return 0;
  };
  filterChains = (chain: Chain, filterBy: string) => chain.name.toLowerCase().includes(filterBy);
  getChainKey = (chain: Chain) => chain.id;
}
