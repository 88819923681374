import 'reflect-metadata';
import '@/assets/css/theme.pcss';

import BotServiceMetadataListCard from '@/components/bot/BotServiceMetadataListCard.vue';
import AppBadge from '@/components/ui/AppBadge.vue';
import AppButton from '@/components/ui/AppButton.vue';
import AppCard from '@/components/ui/AppCard.vue';
import AppCheckbox from '@/components/ui/AppCheckbox.vue';
import AppDropzone from '@/components/ui/AppFileDropzone.vue';
import AppFormCard from '@/components/ui/AppFormCard.vue';
import AppInput from '@/components/ui/AppInput.vue';
import AppInputRow from '@/components/ui/AppInputRow.vue';
import AppList from '@/components/ui/AppList.vue';
import AppListCard from '@/components/ui/AppListCard.vue';
import AppLoadingIndicator from '@/components/ui/AppLoadingIndicator.vue';
import AppModal from '@/components/ui/AppModal.vue';
import AppNotification from '@/components/ui/AppNotification.vue';
import AppSelect from '@/components/ui/AppSelect.vue';
import AppTextarea from '@/components/ui/AppTextarea.vue';
import AppToggle from '@/components/ui/AppToggle.vue';
import PageHeader from '@/components/ui/PageHeader.vue';
import TimezoneSelect from '@/components/ui/TimezoneSelect.vue';
import BlankLayout from '@/layouts/BlankLayout.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { AppRoute } from '@/router/routes';
import {
  NotificationData,
  NotificationType,
  UpdateBotOptions,
  UpdateBotPlatformOptions,
  UpdateChainOptions,
} from '@/types';
import { SupportedPlatform } from 'ignite360-core';
import Vue from 'vue';
import VueTimeago from 'vue-timeago';
import App from './App.vue';
import router from './router';
import store from './store';
import { tenant, TenantData, tenantId } from '@/constants';

Vue.config.productionTip = false;

declare module 'vue/types/vue' {
  interface Vue {
    $routes: typeof AppRoute;

    $tenantId: string;
    $tenant: TenantData;
    $notify: {
      show(notification: NotificationData): void;
      success(notificationOrTitle: Omit<NotificationData, 'type'> | string): void;
      warning(notificationOrTitle: Omit<NotificationData, 'type'> | string): void;
      error(notificationOrTitle: Omit<NotificationData, 'type'> | string): void;
    };

    $updateBot(options: UpdateBotOptions): Promise<void>;

    $updateChain(options: UpdateChainOptions): Promise<void>;

    $updateBotPlatform<PLATFORM extends SupportedPlatform>(
      options: UpdateBotPlatformOptions<PLATFORM>,
    ): Promise<void>;
  }
}

Vue.prototype.$routes = AppRoute;

Vue.prototype.$notify = {
  show(notification: NotificationData) {
    store.commit('notifications/add', notification);
  },
  success(notification: Omit<NotificationData, 'type'> | string) {
    this.show(this._parseData(NotificationType.Success, notification));
  },
  warning(notification: Omit<NotificationData, 'type'> | string) {
    this.show(this._parseData(NotificationType.Warning, notification));
  },
  error(notification: Omit<NotificationData, 'type'> | string) {
    this.show(this._parseData(NotificationType.Error, notification));
  },
  _parseData(type: NotificationType, notification: Omit<NotificationData, 'type'> | string) {
    return typeof notification === 'string'
      ? { type, title: notification }
      : { ...notification, type };
  },
};

Vue.prototype.$showNotification = function (notification: NotificationData) {
  this.$store.commit('notifications/add', notification);
};
Vue.prototype.$updateBot = async function (options: UpdateBotOptions) {
  return this.$store.dispatch('bots/update', options);
};
Vue.prototype.$updateChain = async function (options: UpdateChainOptions) {
  return this.$store.dispatch('chains/update', options);
};
Vue.prototype.$updateBotPlatform = async function <PLATFORM extends SupportedPlatform>(
  options: UpdateBotPlatformOptions<PLATFORM>,
) {
  return this.$store.dispatch('bots/updatePlatform', options);
};

Object.defineProperty(Vue.prototype, '$tenantId', {
  get: function $tenantId() {
    return tenantId;
  },
});

Object.defineProperty(Vue.prototype, '$tenant', {
  get: function $tenant() {
    return tenant;
  },
});

Vue.component('blank-layout', BlankLayout);
Vue.component('default-layout', DefaultLayout);

Vue.component('app-button', AppButton);
Vue.component('app-list', AppList);
Vue.component('app-checkbox', AppCheckbox);
Vue.component('app-input', AppInput);
Vue.component('app-input-row', AppInputRow);
Vue.component('app-select', AppSelect);
Vue.component('app-toggle', AppToggle);
Vue.component('app-badge', AppBadge);
Vue.component('app-textarea', AppTextarea);
Vue.component('app-notification', AppNotification);
Vue.component('app-modal', AppModal);
Vue.component('app-dropzone', AppDropzone);
Vue.component('app-loading-indicator', AppLoadingIndicator);

Vue.component('app-card', AppCard);
Vue.component('app-form-card', AppFormCard);
Vue.component('app-list-card', AppListCard);

Vue.component('timezone-select', TimezoneSelect);
Vue.component('page-header', PageHeader);

Vue.component('bot-service-metadata-list-card', BotServiceMetadataListCard);

Vue.use(VueTimeago, {
  name: 'timeago',
  locale: 'en',
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
