
import { SelectOption } from '@/types';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'app-select',
})
export default class AppSelect extends Vue {
  @Prop({ required: true, type: [String, Number] })
  readonly value!: string | number;

  @Prop({ required: true, type: Array })
  readonly options!: SelectOption[];

  @Prop({ required: false, type: String, default: 'Select an option' })
  readonly placeholder!: string;

  @Prop({ required: false, type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly blank!: boolean;

  @Prop({ required: false, type: [String, Array], default: '' })
  readonly buttonClasses!: string | string[];

  @Prop({ required: false, type: [String, Array], default: '' })
  readonly buttonDisabledClasses!: string | string[];

  @Prop({ required: false, type: String, default: () => null })
  readonly id!: string | null;

  @Prop({ required: false, type: String, default: () => null })
  readonly name!: string | null;

  @Prop({ required: false, type: String, default: () => null })
  readonly autocomplete!: string | null;

  @Prop({ required: false, type: Boolean, default: false })
  readonly clearable!: boolean;

  isPopoverVisible = false;

  get mergedButtonClasses() {
    const classes: string[] = [];
    const addToClasses = (newClasses: string | string[]) => {
      if (typeof newClasses === 'string') {
        classes.push(newClasses);
      } else {
        classes.push(...newClasses);
      }
    };
    addToClasses(this.buttonClasses);
    addToClasses(this.buttonDisabledClasses);
    return classes;
  }

  get selectedOption(): SelectOption | undefined {
    return this.options.find((option) => option.value === this.value);
  }

  get selectedOptionIndex(): number {
    return this.options.findIndex((option) => option.value === this.value);
  }

  focus() {
    (this.$refs.button as HTMLButtonElement | undefined)?.focus();
  }

  togglePopover() {
    if (this.disabled) return;
    this.isPopoverVisible ? this.close() : this.open();
  }

  onSelect(value: SelectOption['value']) {
    this.isPopoverVisible = false;
    this.$emit('input', value);
  }

  onClick(event: MouseEvent) {
    if (
      event.target &&
      (this.$refs.button as HTMLButtonElement | undefined)?.contains(event.target as HTMLElement)
    ) {
      return;
    }
    if (!this.isPopoverVisible || !event.target || !this.$refs.popover) return;
    if (!(this.$refs.popover as HTMLElement).contains(event.target as HTMLElement)) {
      this.close();
    }
  }

  onClear() {
    if (!this.value) return;
    this.$emit('input', '');
  }

  close() {
    this.isPopoverVisible = false;
    this.$emit('close');
    document.body.removeEventListener('click', this.onClick);
  }

  open() {
    this.isPopoverVisible = true;
    this.$emit('open');
    document.body.addEventListener('click', this.onClick);
  }
}
