
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'app-badge',
})
export default class AppBadge extends Vue {
  @Prop({ required: false, type: Boolean, default: false })
  small!: boolean;
}
