import { Api, BASE_URL, BOTS_BASE_URL } from '@/api/index';
import axios, { AxiosRequestConfig } from 'axios';
import {
  Bot,
  FacebookPage,
  SubscribeFacebookPagePayload,
  SubscribeFacebookPageResponse,
  SubscribeFacebookPageResult,
} from 'ignite360-core';

export class FacebookApi {
  static async loadFacebookPages(
    facebookUserId: string,
    accessToken: string,
  ): Promise<FacebookPage[]> {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BASE_URL}/public/facebook/pages`,
      data: {
        facebookUserId,
        accessToken,
      },
    };
    const result = await axios.request<FacebookPage[]>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    return [];
  }

  static async connectBotWithFacebookPage(
    botId: string,
    data: SubscribeFacebookPagePayload,
  ): Promise<SubscribeFacebookPageResponse> {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BASE_URL}/public/bots/${botId}/facebook/subscribe`,
      data,
    };
    const result = await axios.request<SubscribeFacebookPageResponse>(config);
    if (result.status === 200) {
      return result.data;
    }
    throw new Error('Could not connect bot to facebook page!');
  }

  static async disconnectBotFromFacebookPage(botId: string): Promise<void> {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BASE_URL}/public/bots/${botId}/facebook/unsubscribe`,
    };

    const result = await axios.request<SubscribeFacebookPageResult>(config);
    if (result.status === 204) {
      return;
    }
    throw new Error('Could not disconnect bot from facebook page!');
  }

  static async publishTestPost(
    id: string,
    params?: { pageId: string; pageAccessToken: string },
  ): Promise<void> {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BASE_URL}/public/bots/${id}/facebook/publish-listing`,
      data: params,
    };

    const result = await axios.request(config);
    if (result.status === 204) {
      return;
    }
    throw new Error('Could not publish post.');
  }

  static async publishPostForListing(id: string, listingId: string): Promise<Bot> {
    const config = Api.finalizeConfig({
      method: 'POST',
      url: `${BOTS_BASE_URL}/${id}/facebook/publish-listing`,
      data: {
        listingId,
      },
    });

    const result = await axios.request<Bot>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not publish post for listing.');
  }
}
