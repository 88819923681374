import { BASE_URL } from '@/api/index';
import axios, { AxiosRequestConfig } from 'axios';
import { GoogleMyBusinessAccount, GoogleMyBusinessLocation } from 'ignite360-core';

export class GoogleMyBusinessApi {
  static async loadAccounts(authTokenId: string): Promise<GoogleMyBusinessAccount[]> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `${BASE_URL}/public/google-my-business/accounts?auth_token_id=${authTokenId}`,
    };

    const result = await axios.request(config);

    if (result.status === 200 && result.data) {
      return result.data as GoogleMyBusinessAccount[];
    }

    throw new Error(result.data.error);
  }

  static async loadLocations(
    authTokenId: string,
    accountId: string,
  ): Promise<GoogleMyBusinessLocation[]> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `${BASE_URL}/public/google-my-business/locations?auth_token_id=${authTokenId}&account_id=${accountId}`,
    };

    const result = await axios.request(config);

    if (result.status === 200) {
      return result.data as GoogleMyBusinessLocation[];
    }

    throw new Error(result.data.error);
  }

  static async publishTestPost(botId: string, accountId: string, locationId: string) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BASE_URL}/public/bots/${botId}/google-my-business/publish-listing`,
      data: { accountId, locationId },
    };

    const res = await axios.request(config);

    if (res.status !== 204) {
      throw new Error('Something went wrong while trying to post on GoogleMyBusiness.');
    }
  }

  static async connectBotWithGoogleMyBusiness(
    botId: string,
    authTokenId: string,
    accountId: string,
    locationId: string,
  ): Promise<void> {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BASE_URL}/public/bots/${botId}/google-my-business/subscribe`,
      data: { authTokenId, accountId, locationId },
    };

    const res = await axios.request(config);

    if (res.status === 200) {
      return;
    }

    throw new Error(
      res.data.error ||
        'Something went wrong while trying to connect your GoogleMyBusiness location.',
    );
  }

  static async disconnectBotFromGoogleBusiness(botId: string): Promise<void> {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BASE_URL}/public/bots/${botId}/google-my-business/unsubscribe`,
    };
    const res = await axios.request(config);
    if (res.status === 204) {
      return;
    }

    throw new Error(
      res.data.error ||
        'Something went wrong while trying to disconnect your GoogleMyBusiness location.',
    );
  }
}
