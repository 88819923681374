var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-col flex-grow px-2 sm:px-6 lg:px-8 pt-5 pb-4 bg-gray-100 overflow-y-auto"},[_c('div',{staticClass:"flex items-center flex-shrink-0 mt-6"},[_c('bot-select',{attrs:{"value":_vm.$route.params.id || ''},on:{"input":_vm.onBotSelect}})],1),_c('div',{staticClass:"mt-5 flex-grow flex flex-col"},[_c('nav',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.params.id),expression:"$route.params.id"}],staticClass:"flex-1 space-y-8 bg-gray-100",attrs:{"aria-label":"Sidebar"}},[(_vm.hasAnalyticsViewerRole())?_c('div',{staticClass:"space-y-1"},[_c('default-sidebar-link',{attrs:{"exact":"","to":{ name: _vm.$routes.ProjectOverview, params: { id: _vm.$route.params.id } }}},[_vm._v(" Overview ")])],1):_vm._e(),(_vm.hasEditorRole())?_c('div',{staticClass:"space-y-1"},[_c('h3',{staticClass:"px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider",attrs:{"id":"content-headline"}},[_vm._v(" CONTENT ")]),_c('div',{staticClass:"space-y-1",attrs:{"role":"group","aria-labelledby":"content-headline"}},[_c('default-sidebar-link',{attrs:{"exact":false,"to":{ name: _vm.$routes.ProjectContentGeneral, params: { id: _vm.$route.params.id } }}},[_vm._v(" General ")]),_c('default-sidebar-link',{attrs:{"to":_vm.bot
                ? {
                    name:
                      _vm.bot.domain === 'restaurant'
                        ? _vm.$routes.ProjectRestaurantMenu
                        : _vm.$routes.ProjectContentRealEstate,
                    params: { id: _vm.$route.params.id },
                  }
                : '#'}},[_vm._v(" "+_vm._s(_vm.bot && _vm.bot.domain === 'restaurant' ? 'Restaurant' : _vm.bot && _vm.bot.domain === 'real_estate' ? 'Real Estate' : 'Domain')+" ")]),(_vm.announcementFeatureEnabled)?_c('default-sidebar-link',{attrs:{"to":{ name: _vm.$routes.ProjectContentAnnouncements, params: { id: _vm.$route.params.id } }}},[_vm._v(" Announcements ")]):_vm._e(),_c('default-sidebar-link',{attrs:{"to":{ name: _vm.$routes.ProjectContentQnA, params: { id: _vm.$route.params.id } }}},[_vm._v(" Q&A ")])],1)]):_vm._e(),(_vm.hasEditorRole())?_c('div',{staticClass:"space-y-1"},[_c('h3',{staticClass:"px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider",attrs:{"id":"integrations-headline"}},[_vm._v(" INTEGRATIONS ")]),_c('div',{staticClass:"space-y-1",attrs:{"role":"group","aria-labelledby":"integrations-headline"}},[_c('default-sidebar-link',{attrs:{"to":{
              name: _vm.$routes.ProjectIntegrationConversation,
              params: { id: _vm.$route.params.id },
            }}},[_vm._v(" Voice & Chat ")]),_c('default-sidebar-link',{attrs:{"to":{
              name: _vm.$routes.ProjectIntegrationSocialPosting,
              params: { id: _vm.$route.params.id },
            }}},[_vm._v(" Social Posting ")])],1)]):_vm._e(),(_vm.hasAnalyticsViewerRole())?_c('div',{staticClass:"space-y-1"},[_c('h3',{staticClass:"px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider",attrs:{"id":"analytics-headline"}},[_vm._v(" ANALYTICS ")]),_c('div',{staticClass:"space-y-1",attrs:{"role":"group","aria-labelledby":"analytics-headline"}},[_c('default-sidebar-link',{attrs:{"to":{
              name: _vm.$routes.ProjectAnalyticsInbox,
              params: { id: _vm.$route.params.id },
            }}},[_c('div',{staticClass:"flex items-center"},[_vm._v(" Inbox "),(_vm.unreadConversations > 0)?_c('span',{staticClass:"bg-primary-700 rounded-lg flex items-center justify-center text-white p-0.5 ml-1 px-1.5 text-xs"},[_vm._v(_vm._s(_vm.unreadConversations))]):_vm._e()])])],1)]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }