import { BASE_URL } from '@/api';
import axios, { AxiosRequestConfig } from 'axios';
import { YextEntityData, YextResponse } from 'ignite360-core';

export interface YextCredentials {
  apiKey: string;
  accountId?: string;
  version?: string;
}

export interface LoadOptions extends YextCredentials {
  fields?: Array<keyof YextEntityData | string>;
  resolvePlaceholders?: boolean;
}

export interface LoadEntitiesOptions extends LoadOptions {
  limit?: number;
  offset?: number;
  pageToken?: string;
  languages?: string[];
}

export interface LoadEntityOptions extends LoadOptions {
  entityId: string;
}

export interface LoadLoadableOptions extends LoadEntityOptions {
  apiPath: string;
  pathId: string;
}

export interface LoadEntitiesResult {
  count: number;
  entities: YextEntityData[];
  pageToken: number;
}

export class YextApi {
  static async loadAllEntities(
    credentials: YextCredentials,
    currentEntities?: YextEntityData[],
  ): Promise<YextEntityData[]> {
    const entities = [];
    const { apiKey, accountId, version } = credentials;

    let count = 0;
    let result!: LoadEntitiesResult;
    do {
      const options: LoadEntitiesOptions = {
        apiKey,
        accountId,
        version,
        fields: ['name'],
        limit: 50,
      };

      if (result && count < result.count && result.pageToken) {
        options.pageToken = result.pageToken.toString();
      }

      result = await this.loadEntities(options);

      if (currentEntities && result.count === currentEntities.length) {
        return currentEntities;
      }

      count += result.entities.length;
      entities.push(...result.entities);
    } while (count < result.count);

    return entities;
  }

  static async loadEntities(options: LoadEntitiesOptions): Promise<LoadEntitiesResult> {
    options = this.prepareOptions(options);
    const { accountId, apiKey, version, offset, limit, fields, pageToken } = options;

    let url = `${this.baseUrl}/v2/accounts/${accountId}/entities?api_key=${apiKey}&v=${version}`;
    if (limit) {
      url += `&limit=${limit > 50 ? 50 : limit}`;
    }
    if (offset) {
      url += `&offset=${offset}`;
    }

    if (fields) {
      url += '&fields=';
      fields.forEach((field: string, index: number) => {
        url += field;
        if (index !== fields.length - 1) {
          url += ',';
        }
      });
    }
    if (pageToken) {
      url += `&pageToken=${pageToken}`;
    }

    const config: AxiosRequestConfig = {
      method: 'GET',
      url,
    };

    return this.makeRequest<LoadEntitiesResult>(config);
  }

  static async loadYextEntityData(options: LoadEntityOptions): Promise<YextEntityData> {
    options = this.prepareOptions(options);
    const { accountId, entityId, apiKey, version } = options;

    const url = `${this.baseUrl}/v2/accounts/${accountId}/entities/${entityId}?api_key=${apiKey}&v=${version}`;
    const config: AxiosRequestConfig = {
      method: 'GET',
      url,
    };

    return this.makeRequest<YextEntityData>(config);
  }

  static async loadLoadable<T>(options: LoadLoadableOptions): Promise<T> {
    options = this.prepareOptions(options);
    const { accountId, apiPath, pathId, apiKey, version } = options;

    const url = `${this.baseUrl}/v2/accounts/${accountId}/${apiPath}/${pathId}?api_key=${apiKey}&v=${version}`;

    const config: AxiosRequestConfig = {
      method: 'GET',
      url,
    };

    return this.makeRequest<T>(config);
  }

  static prepareVersion(date: Date = new Date(), version?: string): string {
    return (
      version ||
      `${date.getFullYear()}${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}`
    );
  }

  static versionToDate(version: string): Date {
    if (version.length !== 8) {
      return new Date();
    }
    const year = +version.substr(0, 4);
    const month = +version.substr(4, 2);
    const date = +version.substr(6, 2);
    const dateObj = new Date();
    dateObj.setFullYear(year, month - 1, date);
    return dateObj;
  }

  private static get baseUrl(): string {
    return `${BASE_URL}/yext`;
  }

  private static async makeRequest<T = {}>(config: AxiosRequestConfig): Promise<T> {
    const response = await axios.request<YextResponse<T>>(config);

    if (response.status !== 200 || !response.data || response.data.meta.errors.length > 0) {
      let text = `Could not reach Yext-API! status-code: ${response.status}`;
      if (response.data && response.data.meta.errors.length > 0) {
        text += `, errors: ${JSON.stringify(response.data.meta.errors, undefined, 2)}`;
      }
      throw new Error(text);
    }

    return response.data.response;
  }

  private static prepareOptions<T extends LoadOptions>(options: T): T {
    if (!options.accountId) {
      options.accountId = 'me';
    }

    if (!options.version) {
      options.version = this.prepareVersion();
    }

    return options;
  }
}
