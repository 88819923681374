
import { BotApi } from '@/api';
import BotSocialPlatformsListCard from '@/components/bot/BotSocialPlatformsListCard.vue';
import {
  GENERIC_ERROR_DESCRIPTION,
  PLATFORMS_METADATA,
  SOCIAL_PLATFORMS_METADATA,
} from '@/constants';
import { AppRoute } from '@/router/routes';
import { PublicBot, SupportedPlatform } from 'ignite360-core';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'public-social-login',
  components: { BotSocialPlatformsListCard },
})
export default class PublicSocialLogin extends Vue {
  isLoading = false;

  bot: PublicBot | null = null;

  get botId(): string {
    return this.$route.params.id || '';
  }

  get socialPlatformRouteMap(): Partial<Record<SupportedPlatform, AppRoute>> {
    return {
      [SupportedPlatform.FacebookMessenger]: AppRoute.PublicSocialLoginFacebook,
      [SupportedPlatform.GoogleBusiness]: AppRoute.PublicSocialLoginGoogleMyBusiness,
      [SupportedPlatform.Instagram]: AppRoute.PublicSocialLoginInstagram,
      [SupportedPlatform.LinkedIn]: AppRoute.PublicSocialLoginLinkedIn,
    };
  }

  get socialPlatformsMetadata() {
    const instagramPlatformMetadata = PLATFORMS_METADATA.find(
      (metadata) => metadata.value === SupportedPlatform.Instagram,
    );
    const socialPlatforms = SOCIAL_PLATFORMS_METADATA.slice();
    if (instagramPlatformMetadata) {
      socialPlatforms.push(instagramPlatformMetadata);
    }
    socialPlatforms.sort((a, b) => a.label.localeCompare(b.label));
    return socialPlatforms.map((metadata) => ({
      ...metadata,
      routeName: this.socialPlatformRouteMap[metadata.value],
    }));
  }

  async created() {
    this.isLoading = true;
    try {
      this.bot = await BotApi.publicGetBot(this.botId);
    } catch (e) {
      this.$notify.error({
        title: 'Loading project failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isLoading = false;
    }
  }
}
