import { Component, Vue } from 'vue-property-decorator';
import { UserRole } from 'ignite360-core';

@Component
export class AuthMixin extends Vue {
  get user() {
    return this.$store.state.auth.user;
  }

  hasPermission(role: UserRole): boolean {
    const botId = this.$route.params.id;

    if (!this.user) {
      return false;
    }
    if (this.user.botRoles[botId] <= role || this.user.role === UserRole.SuperAdmin) {
      return true;
    }

    return false;
  }

  hasSuperAdminRole(): boolean {
    return this.hasPermission(UserRole.SuperAdmin);
  }

  hasEditorRole(): boolean {
    return this.hasPermission(UserRole.Editor);
  }

  hasAnalyticsViewerRole(): boolean {
    return this.hasPermission(UserRole.AnalyticsViewer);
  }

  hasAdminRole(): boolean {
    const projectId = this.$route.fullPath.startsWith('/projects/') ? this.$route.params.id : '';
    const roles = this.user.botRoles || {};
    return roles[projectId] === UserRole.Admin || this.hasSuperAdminRole();
  }
}
