
import {Component, Vue} from 'vue-property-decorator';
import ProjectSidebar from "@/components/default-layout/ProjectSidebar.vue";
import ChainSidebar from "@/components/default-layout/ChainSidebar.vue";

@Component({
  name: 'default-sidebar',
  components: { ProjectSidebar, ChainSidebar },
})
export default class DefaultSidebar extends Vue {
    get sidebar() {
      return this.$route.name?.startsWith('project') ? 'project-sidebar' : 'chain-sidebar'
    }
}
