
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'app-textarea',
})
export default class AppTextarea extends Vue {
  @Prop({ required: true, type: String })
  readonly value!: string;

  @Prop({ required: false, type: String })
  readonly placeholder?: string;

  focus(options?: FocusOptions) {
    (this.$el as HTMLDivElement).focus(options);
  }

  @Watch('value')
  onValueChange(val: string) {
    if (document.activeElement?.isSameNode(this.$el)) return;
    (this.$el as HTMLDivElement).innerText = val;
  }
}
