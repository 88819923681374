
import { SOCIAL_PLATFORMS_METADATA } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'bot-social-platforms-list-card',
})
export default class BotSocialPlatformsListCard extends mixins(BotMixin) {
  get socialPlatformsMetadata() {
    return SOCIAL_PLATFORMS_METADATA;
  }
}
