
import { Api } from '@/api';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { Component, Vue } from 'vue-property-decorator';
import ChainListCard from '@/components/chain/ChainListCard.vue';
import ChainCreateModal from '@/components/chain/ChainCreateModal.vue';

@Component({
  name: 'chains',
  components: { ChainCreateModal, ChainListCard },
})
export default class Chains extends Vue {
  isLoading = true;
  showModal = false;

  async created() {
    Api.setAuthorization(this.$store.state.auth.authorization);
    this.isLoading = true;
    try {
      await Promise.all([
        this.$store.dispatch('chains/fetchAll'),
        this.$store.dispatch('bots/fetchAll'),
      ]);
    } catch (e) {
      this.$notify.error({
        title: 'Loading chains failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isLoading = false;
    }
  }
}
