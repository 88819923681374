
import { Api } from '@/api';
import BotCreateModal from '@/components/bot/BotCreateModal.vue';
import BotListCard from '@/components/bot/BotListCard.vue';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'projects',
  components: { BotCreateModal, BotListCard },
})
export default class Projects extends Vue {
  isLoading = true;
  showModal = false;

  async created() {
    Api.setAuthorization(this.$store.state.auth.authorization);
    this.isLoading = true;
    try {
      await this.$store.dispatch('bots/fetchAll');
    } catch (e) {
      this.$notify.error({
        title: 'Loading projects failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isLoading = false;
    }
  }
}
