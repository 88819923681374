
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'app-modal',
})
export default class AppModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly show!: boolean;

  @Prop({ required: false, type: String, default: '' })
  readonly header!: string;

  @Prop({ required: false, type: [String, Array], default: '' })
  readonly modalClass!: string | string[];

  @Prop({ required: false, type: [String, Array], default: '' })
  readonly containerClass!: string | string[];

  @Prop({ required: false, type: [String, Array], default: '' })
  readonly bodyClass!: string | string[];

  @Prop({ required: false, type: [String, Array], default: '' })
  readonly helperClass!: string | string[];

  @Prop({ required: false, type: [String, Array], default: '' })
  readonly footerClass!: string | string[];

  @Prop({ required: false, type: Boolean, default: false })
  readonly fullBody!: boolean;

  close() {
    this.$emit('update:show', false);
  }
}
