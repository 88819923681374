
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'app-list-card',
})
export default class AppListCard<T extends any> extends Vue {
  @Prop({ required: true, type: Array })
  readonly items!: T[];

  @Prop({ required: true, type: Function })
  readonly sortFn!: (a: T, b: T) => number;

  @Prop({ required: true, type: Function })
  readonly filterFn!: (item: T, filterBy: string) => boolean;

  @Prop({ required: true, type: Function })
  readonly getItemKeyFn!: (item: T, index: number) => string;

  @Prop({ required: false, type: String })
  readonly header!: string;

  @Prop({ required: false, type: String })
  readonly newButtonText!: string | null;

  @Prop({ required: false, type: Boolean, default: false })
  readonly hideSearch!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly hideButton!: boolean;

  @Prop({ required: false, type: Boolean, default: true })
  readonly scrollable!: boolean;

  @Prop({ required: false, type: String, default: 'search' })
  readonly searchId!: string;

  @Prop({ required: false, type: String })
  readonly filter?: string;

  @Prop({ required: false, type: Boolean, default: false })
  readonly canCreate!: boolean;

  filterBy = '';

  get filteredItems(): T[] {
    return this.usedFilter
      ? this.items.filter((item) => this.filterFn(item, this.usedFilter.toLowerCase()))
      : this.items;
  }

  get sortedItems(): T[] {
    return this.filteredItems.slice().sort(this.sortFn);
  }

  get usedFilter(): string {
    return this.filter || this.filterBy;
  }
}
