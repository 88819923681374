
import { Api, BotApi } from '@/api';
import { Component, Vue } from 'vue-property-decorator';
import { UserStatus } from 'ignite360-core';
import { AppRoute } from '@/router/routes';

export interface AcceptInvitationModel {
  email: string;
  password: string;
  passwordconfirm: string;
  status?: UserStatus;
  code: string;
}

@Component({
  name: 'reset-password',
})
export default class AcceptInvitation extends Vue {
  model: AcceptInvitationModel = {
    email: '',
    password: '',
    passwordconfirm: '',
    status: undefined,
    code: '',
  };

  hint = '';

  isLoading = false;

  async mounted() {
    if (this.$route.query.code) {
      this.model.code = this.$route.query.code as string;
      await this.loadProjectData();
      document.getElementById('password')!.focus();
    }
  }

  async loadProjectData() {
    try {
      const data = await BotApi.getBotNameByInvitationCode(this.model.code);
      this.model.email = data.email;
      this.model.status = data.status;
      this.hint = `You're invited to collaborate on <b>${data.botName}</b>. `;
      if (this.model.status === UserStatus.Pending) {
        this.hint += `Please set an password to create an account and get started.`;
      } else {
        this.hint += `Please login to get started.`;
      }
    } catch (e) {
      console.log(e);
    }
  }

  get buttonText() {
    return this.model.status === UserStatus.Active ? 'Login and accept' : 'Set password and accept';
  }

  async onSubmit() {
    if (!this.model.code) {
      return;
    }

    this.isLoading = true;
    try {
      const user = await Api.acceptInvitation(this.model);
      this.$notify.success('Invitation accepted.');
      this.$store.commit('auth/setUser', user.data);

      await this.$router.replace({ name: AppRoute.Projects });
    } catch (e) {
      console.log(e);
      this.$notify.error({
        title: 'Setting up collaboration failed',
        description: e.response.data.details,
      });
    } finally {
      this.isLoading = false;
    }
  }
  isPendingUser() {
    return this.model.status === UserStatus.Pending;
  }
}
