import { EmptyDomainAuResponseError } from '@/api/errors/EmptyDomainAuResponseError';
import axios, { AxiosRequestConfig } from 'axios';
import { DomainAuAgencySummary, DomainAuAgentSummary } from 'ignite360-core';
import _set from 'lodash.set';
import { BASE_URL } from '@/api/index';

export class DomainAuApi {
  static queryAgents(query: string): Promise<DomainAuAgentSummary[]> {
    const queryString = encodeURIComponent(query);
    const apiPath = `/agents/search?query=${queryString}`;
    return this.makeRequest({
      url: `${BASE_URL}/domain-au/proxy`,
      method: 'POST',
      data: {
        apiPath,
      },
    });
  }

  static queryAgencies(query: string): Promise<DomainAuAgencySummary[]> {
    // currently domain has a (bug|feature?) that the query for searching agencies cannot interpret uriencoded spaces
    query = query.replace(/\s/g, '_');
    const queryString = encodeURIComponent(query);
    const apiPath = `/agencies?q=${queryString}`;
    return this.makeRequest({
      url: `${BASE_URL}/domain-au/proxy`,
      method: 'POST',
      data: {
        apiPath,
      },
    });
  }

  private static async makeRequest<T = {}>(config: AxiosRequestConfig) {
    // _set(config, `headers['X-API-Key']`, this.apiKey);
    // config.validateStatus = () => true;
    // config.withCredentials = false;
    const response = await axios.request<T>(config);
    if (response.status === 200 && response.data) {
      return response.data;
    } else if (response.status === 404) {
      throw new EmptyDomainAuResponseError();
    }
    throw new Error(
      `Could not retrieve data from Domain.com.au-API! status-code: ${response.status}`,
    );
  }
}
