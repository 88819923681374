
import { AppRoute } from '@/router/routes';
import { Component, Vue } from 'vue-property-decorator';
import { Api } from '@/api';
import { PUBLIC_ROUTES } from '@/constants';

@Component({
  name: 'app',
  components: {},
})
export default class App extends Vue {
  routerReady = false;

  get layout(): string {
    return this.$route.meta?.layout || 'default';
  }

  get isDebugMode(): boolean {
    // tslint:disable-next-line
    return process.env.VUE_APP_DEBUG_MODE == 'true';
  }

  get debugCredentials(): string | undefined {
    return process.env.VUE_APP_DEBUG_CREDENTIALS;
  }

  get isAuthenticated(): boolean {
    return this.$store.getters['auth/isAuthenticated'];
  }

  applyTenant() {
    document.body.classList.add(this.$tenantId);
    document.title = this.$tenant.title;

    // add favicon to html
    const favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = `/${this.$tenantId}/favicon-16x16.png`;
    document.head.appendChild(favicon);
  }

  async created() {
    this.applyTenant();
    this.$router.onReady(async () => {
      this.routerReady = true;

      try {
        // check if auth cookie is still active, if not route back to login
        await Api.user();

        // redirect to projects if user is already authenticated
        // await this.$router.replace({ name: AppRoute.Projects });
      } catch (e) {
        if (!PUBLIC_ROUTES.includes(this.$route.name as AppRoute)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore // @typescript-eslint/ban-ts-ignore
          return this.$router.replace({ name: AppRoute.Login, params: { backToLogin: true } });
        }
      }
      if (this.isDebugMode && this.debugCredentials) {
        this.$store.commit('auth/setAuthorization', btoa(this.debugCredentials));
        if (!this.$route.name || this.$route.name === AppRoute.Login) {
          return this.$router.replace({ name: AppRoute.Projects });
        }
      }
    });
  }
}
