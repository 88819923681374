import { Api } from '@/api';
import { Module } from 'vuex';
import { RootState } from '..';
import { User } from 'ignite360-core';

export interface AuthModuleState {
  authorization: string | null;
  user: User | null;
}

export function setAuthorization(authorization: AuthModuleState['authorization']) {
  authorization
    ? sessionStorage.setItem('authorization', authorization)
    : sessionStorage.removeItem('authorization');
  Api.setAuthorization(authorization);
}

export const AuthModule: Module<AuthModuleState, RootState> = {
  namespaced: true,
  state: {
    authorization: sessionStorage.getItem('authorization'),
    user: null,
  },
  getters: {
    isAuthenticated(state) {
      return !!state.user;
    },
  },
  mutations: {
    setAuthorization(state, authorization: AuthModuleState['authorization']) {
      setAuthorization(authorization);
      state.authorization = authorization;
    },

    setUser(state, user: AuthModuleState['user']) {
      state.user = user;
    },
  },
  actions: {
    async login(context, data: { email: string; password: string }) {
      // let authorization = null;
      await Api.login(data);
      // authorization = btoa(`${data.username}:${data.password}`);
      // context.commit(q'setAuthorization', authorization);
    },

    async logout(context) {
      context.commit('setUser', null);
    },
  },
};
