
import { DOMAIN_CHAIN_FEATURE_MAP, FEATURE_METADATA_MAP } from '@/constants';
import { BotDomain, SupportedFeature } from 'ignite360-core';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'chain-features-checkbox-group',
})
export default class ChainFeaturesCheckboxGroup extends Vue {
  @Prop({ required: true, type: String })
  readonly domain!: BotDomain;

  @Prop({ required: true, type: Set })
  readonly value!: Set<SupportedFeature>;

  get domainFeatureMap() {
    return DOMAIN_CHAIN_FEATURE_MAP;
  }

  get domainFeatures(): SupportedFeature[] {
    return this.domainFeatureMap[this.domain];
  }

  get featureMetadataMap() {
    return FEATURE_METADATA_MAP;
  }

  toggleFeature(feature: SupportedFeature) {
    const valueCopy = new Set(this.value);
    if (valueCopy.has(feature)) {
      valueCopy.delete(feature);
    } else {
      valueCopy.add(feature);
    }
    this.$emit('input', valueCopy);
  }
}
