import { Api, BASE_URL, BOTS_BASE_URL } from '@/api/index';
import axios, { AxiosRequestConfig } from 'axios';
import {
  Bot,
  CreateBot,
  Environment,
  SupportedPlatform,
  UpdateBot,
  PublicBot,
  BotPlatformData,
  CreateCollaborationInvitation,
} from 'ignite360-core';

export class BotApi {
  static async publicGetBot(id: string): Promise<PublicBot> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `${BASE_URL}/public/bots/${id}`,
    };

    const result = await axios.request<Bot>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not load bot.');
  }

  static async createBot(data: CreateBot): Promise<Bot> {
    const config = Api.finalizeConfig({
      method: 'POST',
      url: BOTS_BASE_URL,
      data,
    });

    const result = await axios.request<Bot>(config);
    if (result.status === 201 && result.data) {
      return result.data;
    }
    throw new Error('Could not create bot.');
  }

  static async loadBots(): Promise<Bot[]> {
    const config = Api.finalizeConfig({
      method: 'GET',
      url: BOTS_BASE_URL,
    });

    const result = await axios.request<Bot[]>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    return [];
  }

  static async updateBot(id: string, data: UpdateBot): Promise<Bot> {
    const config = Api.finalizeConfig({
      method: 'PUT',
      url: `${BOTS_BASE_URL}/${id}`,
      data,
    });

    const result = await axios.request<Bot>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not update bot.');
  }

  static async sendCollaborationInvite(
    id: string,
    data: CreateCollaborationInvitation,
  ): Promise<any> {
    const config = Api.finalizeConfig({
      method: 'PUT',
      url: `${BOTS_BASE_URL}/${id}/collaborators`,
      data,
    });

    const result = await axios.request(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not update bot.');
  }

  static async getBotNameByInvitationCode(invitationCode: string): Promise<any> {
    const config = Api.finalizeConfig({
      method: 'GET',
      url: `${BOTS_BASE_URL}/bot-name-by-invitation-code/${invitationCode}`,
    });

    const result = await axios.request(config);

    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not update bot.');
  }

  static async deleteCollaboration(botId: string, userId: string): Promise<any> {
    const config = Api.finalizeConfig({
      method: 'DELETE',
      url: `${BOTS_BASE_URL}/${botId}/collaborators/${userId}`,
    });

    const result = await axios.request(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not update bot.');
  }

  static async updateBotPlatform<PLATFORM extends SupportedPlatform>(
    id: string,
    platform: PLATFORM,
    data: Partial<BotPlatformData[PLATFORM]>,
  ): Promise<BotPlatformData[PLATFORM]> {
    const config = Api.finalizeConfig({
      method: 'PUT',
      url: `${BOTS_BASE_URL}/${id}/platform/${platform}`,
      data,
    });

    const result = await axios.request<BotPlatformData[PLATFORM]>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not update platform.');
  }

  static async deleteBot(id: string): Promise<Bot> {
    const config = Api.finalizeConfig({
      method: 'DELETE',
      url: `${BOTS_BASE_URL}/${id}`,
    });

    const result = await axios.request<Bot>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not delete bot.');
  }
}
