
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'app-file-dropzone',
})
export default class AppFileDropzone extends Vue {
  @Prop({ required: false, type: String, default: '' })
  readonly accept!: HTMLInputElement['accept'];

  canDropFile = false;

  openFileDialog() {
    (this.$refs.fileInput as HTMLInputElement | undefined)?.click();
  }

  onDragOver(event: DragEvent) {
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = 'copy';
    }
    this.canDropFile = true;
  }

  onDragLeave(event: DragEvent) {
    this.canDropFile = false;
  }

  onDrop(event: DragEvent) {
    this.canDropFile = false;
    if (!event.dataTransfer?.files?.length) return;
    this.$emit('input', event.dataTransfer.files[0]);
  }

  onFileSelect() {
    const fileInput = this.$refs.fileInput as HTMLInputElement | undefined;
    if (!fileInput?.files?.length) return;
    this.$emit('input', fileInput.files[0]);
    // clear internal value to be able to immediately reselect
    fileInput.value = '';
  }
}
