export enum AppRoute {
  Login = 'login',
  ForgotPassword = 'forgot-password',
  ResetPassword = 'reset-password',
  AcceptInvitation = 'accept-invitation',

  Projects = 'projects',
  ProjectOverview = 'project.overview',
  ProjectContentGeneral = 'project.content.general',

  ProjectDataSourceYext = 'project.data-source.yext',
  ProjectContentAnnouncements = 'project.content.announcements',
  ProjectContentQnA = 'project.content.qna',

  ProjectContentMessages = 'project.content.messages',

  ProjectContentRestaurant = 'project.content.restaurant',
  ProjectRestaurantMenu = 'project.content.restaurant.menu',
  ProjectRestaurantBooking = 'project.content.restaurant.booking',
  ProjectRestaurantOrder = 'project.content.restaurant.order',

  ProjectContentRealEstate = 'project.content.real-estate',
  ProjectRealEstateListings = 'project.content.real-estate.listings',
  ProjectRealEstateDomainComAu = 'project.content.real-estate.domain-com-au',
  ProjectRealEstateAgentbox = 'project.content.real-estate.agentbox',
  ProjectRealEstateEagleCRM = 'project.content.real-estate.eagle-crm',
  ProjectRealEstateManualEntry = 'project.content.real-estate.manual-entry',

  ProjectIntegrationConversation = 'project.integration.conversation',
  ProjectIntegrationSocialPosting = 'project.integration.social-posting',

  ProjectAnalyticsInbox = 'project.analytics.inbox',
  ProjectAnalyticsInboxConversation = 'project.analytics.inbox.conversation',
  ProjectAnalyticsInboxConversationSessions = 'project.analytics.inbox.conversation.sessions',

  ProjectPlatformAmazonAlexa = 'project.platform.amazon-alexa',
  ProjectPlatformFacebookMessenger = 'project.platform.facebook-messenger',
  ProjectPlatformGoogleAssistant = 'project.platform.google-assistant',
  ProjectPlatformInstagram = 'project.platform.instagram',
  ProjectPlatformGoogleBusinessMessages = 'project.platform.google-business-messages',

  ProjectSocialPlatformFacebook = 'project.social-platform.facebook',
  ProjectSocialPlatformGoogleMyBusiness = 'project.social-platform.google-my-business',
  ProjectSocialPlatformLinkedIn = 'project.social-platform.linked-in',
  ProjectSocialPlatformInstagram = 'project.social-platform.instagram',

  PublicSocialLogin = 'public.social-login',
  PublicSocialLoginFacebook = 'public.social-login.facebook',
  PublicSocialLoginGoogleMyBusiness = 'public.social-login.google-my-business',
  PublicSocialLoginInstagram = 'public.social-login.instagram',
  PublicSocialLoginLinkedIn = 'public.social-login.linked-in',

  Chains = 'chains',
  ChainOverview = 'chain.overview',
  ChainContentGeneral = 'chain.content.general',
  ChainContentAnnouncements = 'chain.content.announcements',
}
