
import { ucwords } from 'ignite360-core';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { RawLocation, Route } from 'vue-router';

interface BreadcrumbMenuItem {
  name: string;
  to: RawLocation;
}

function getReadableRouteName(
  route: Pick<Route, 'meta' | 'name'>,
  breadcrumbNameOverrideMap: Record<string, string> = {},
): string {
  let name = (route.name ? breadcrumbNameOverrideMap[route.name] : undefined) || route.meta?.name;
  if (!name && route.name) {
    const lastDotIndex = route.name.lastIndexOf('.');
    const endPart = lastDotIndex >= 0 ? route.name.substr(lastDotIndex + 1) : route.name;
    name = ucwords(endPart.replace(/-/g, ' '));
  }
  return name;
}

@Component({
  name: 'page-header',
})
export default class PageHeader extends Vue {
  @Prop({ required: false, type: [Object, String], default: () => null })
  readonly rootLocation!: RawLocation | null;

  @Prop({ required: false, type: Object, default: () => ({}) })
  readonly breadcrumbNameOverrideMap!: Record<string, string>;

  @Prop({ required: false, type: Boolean, default: false })
  readonly hideBreadcrumbs!: boolean;

  @Prop({
    required: false,
    type: String,
    default: function (this: PageHeader) {
      return getReadableRouteName(this.$route, this.breadcrumbNameOverrideMap);
    },
  })
  readonly name!: string;

  @Prop({ required: false, type: String, default: () => null })
  readonly description!: string | null;

  breadcrumbMenu: BreadcrumbMenuItem[] = [];

  @Watch('rootLocation', { immediate: true, deep: true })
  onRootLocationChange() {
    this.buildMenu();
  }

  buildMenu() {
    if (!this.rootLocation) return;
    const rootResult = this.$router.resolve(this.rootLocation);
    const { resolved } = this.$router.resolve({ ...this.$route, name: this.$route.name! });
    const startIndex = resolved.matched.findIndex((match) => match.name === rootResult.route.name);
    const relevantMatched = resolved.matched.slice(startIndex);
    this.breadcrumbMenu = relevantMatched.map((match) => {
      return {
        name: getReadableRouteName(match, this.breadcrumbNameOverrideMap),
        to: { name: match.name },
      };
    });
  }
}
