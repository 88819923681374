
import { BotMixin } from '@/mixins/BotMixin';
import { PlatformMetadata, ServiceMetadata } from '@/types';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { AuthMixin } from '@/mixins/AuthMixin';

@Component({
  name: 'bot-service-metadata-list-card',
})
export default class BotServiceMetadataListCard extends mixins(BotMixin, AuthMixin) {
  @Prop({ required: true, type: Array })
  readonly items!: ServiceMetadata[];

  @Prop({ required: true, type: String })
  readonly header!: string;

  @Prop({ required: false, type: Boolean, default: false })
  readonly hideSearch!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly showButton!: boolean;

  @Prop({ required: false, type: Boolean, default: true })
  readonly scrollable!: boolean;

  sortItems = (a: ServiceMetadata, b: ServiceMetadata) => a.label.localeCompare(b.label);
  filterItems = (metadata: ServiceMetadata, filterBy: string) =>
    metadata.label.toLowerCase().includes(filterBy);
  getItemKey = (metadata: ServiceMetadata) => metadata.value || metadata.label;

  getStatusText(item: PlatformMetadata): string {
    const status = item.status?.(this.bot);
    return status && typeof status === 'object' && status.text
      ? status.text
      : status && typeof status === 'string'
      ? status
      : item.isDisabled && item.isDisabled(this.bot)
      ? 'Disabled'
      : 'Connected';
  }

  getStatusClass(item: PlatformMetadata): string {
    const status = item.status?.(this.bot);
    return status && typeof status === 'object' && status.class
      ? status.class
      : item.isDisabled && item.isDisabled(this.bot)
      ? 'bg-red-100 text-red-800'
      : 'bg-green-100 text-green-800';
  }
}
