
import { DEFAULT_BUTTON_SIZE, DEFAULT_BUTTON_TYPE } from '@/constants';
import { ButtonSize, ButtonType } from '@/types';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'app-button',
})
export default class AppButton extends Vue {
  @Prop({ required: false, type: String, default: DEFAULT_BUTTON_TYPE })
  readonly type!: ButtonType;

  @Prop({ required: false, type: String, default: DEFAULT_BUTTON_SIZE })
  readonly size!: ButtonSize;

  @Prop({ required: false, type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly circle!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly loading!: boolean;

  @Prop({ required: false, type: String, default: 'button' })
  readonly nativeType!: string;

  // TODO: check if beneath can be parsed by purgecss somehow
  get classes() {
    return [
      'button',
      `button-${this.type}`,
      `button-${this.size}`,
      this.disabled || this.loading ? 'button-disabled' : '',
      this.circle ? 'button-circle' : '',
      this.$tenantId === 'ignite360'
        ? 'bg-ignite360-600 hover:bg-ignite360-700'
        : 'bg-tanis-600 hover:bg-tanis-700',
    ];
  }
}
