
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'app-input',
})
export default class AppInput extends Vue {
  @Prop({ required: true, type: [String, Number] })
  readonly value!: string | number;

  @Prop({ required: false, type: String, default: 'text' })
  readonly type!: string;

  @Prop({ required: false, type: Boolean, default: false })
  readonly required!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly disabled!: boolean;
}
