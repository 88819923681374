
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'bot-avatar',
})
export default class BotAvatar extends Vue {
  @Prop({ required: true, type: String })
  readonly botName!: string;

  get initials() {
    return this.botName
      .split(' ')
      .slice(0, 2)
      .map((text) => text[0]?.toUpperCase())
      .join('');
  }
}
