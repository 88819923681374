
import DefaultSidebar from '@/components/default-layout/DefaultSidebar.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'default-sidebar-mobile',
  components: { DefaultSidebar },
})
export default class DefaultSidebarMobile extends Vue {
  @Prop({ required: true, type: Boolean })
  show!: boolean;

  close() {
    this.$emit('update:show', false);
  }

  @Watch('$route', { deep: true })
  onRouteChange() {
    this.$emit('update:show', false);
  }
}
