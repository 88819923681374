
import {GENERIC_ERROR_DESCRIPTION} from '@/constants';
import isEqual from 'fast-deep-equal/es6';
import {BotDomain, CreateChain} from 'ignite360-core';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {ChainSettings, SelectOption} from "@/types";
import ChainForm from "@/components/chain/ChainForm.vue";

@Component({
  name: 'chain-create-modal',
  components: { ChainForm },
})
export default class ChainCreateModal extends Vue {
  @Prop({ required: true, type: Boolean, default: false })
  readonly show!: boolean;

  isSubmitting = false;

  chainSettings = this.resetValue();

  get settingsHaveChanged(): boolean {
    return !isEqual(this.chainSettings, this.resetValue());
  }

  async onSubmit() {
    const data: CreateChain = {
      ...this.chainSettings,
      selectedFeatures: Array.from(this.chainSettings.selectedFeatures),
    };
    this.isSubmitting = true;
    try {
      await this.$store.dispatch('chains/create', data);
      this.$notify.success('Successfully created chain');
      this.$emit('update:show', false);
    } catch (e) {
      this.$notify.error({
        title: 'Creating a project failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  resetValue(): ChainSettings {
    return {
      name: '',
      bots: [],
      domain: BotDomain.RealEstate,
      selectedFeatures: new Set(),
    };
  }

  @Watch('show')
  reset() {
    this.chainSettings = this.resetValue();
  }
}
