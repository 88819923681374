
import BotAvatar from '@/components/bot/BotAvatar.vue';
import { Bot } from 'ignite360-core';
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { AuthMixin } from '@/mixins/AuthMixin';

@Component({
  name: 'bot-list-card',
  components: { BotAvatar },
})
export default class BotListCard extends mixins(AuthMixin) {
  get bots(): Bot[] {
    return this.$store.state.bots.bots;
  }

  // sort by last updated desc
  sortBots = (a: Bot, b: Bot) => {
    const lastUpdatedA = new Date(a.lastUpdatedAt).getTime();
    const lastUpdatedB = new Date(b.lastUpdatedAt).getTime();

    if (lastUpdatedA > lastUpdatedB) {
      return -1;
    } else if (lastUpdatedA < lastUpdatedB) {
      return 1;
    }
    return 0;
  };
  filterBots = (bot: Bot, filterBy: string) => bot.name.toLowerCase().includes(filterBy);
  getBotKey = (bot: Bot) => bot.id;
}
