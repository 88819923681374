
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'default-navbar-mobile',
  components: {},
})
export default class DefaultNavbarMobile extends Vue {
  @Prop({ required: true, type: Boolean })
  isVisible!: boolean;
}
