
import BotAvatar from '@/components/bot/BotAvatar.vue';
import { SelectOption } from '@/types';
import { Bot } from 'ignite360-core';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'bot-select',
  components: { BotAvatar },
})
export default class BotSelect extends Vue {
  @Prop({ required: false, type: String })
  readonly value!: Bot['id'];

  @Prop({ required: false, type: String, default: () => null })
  readonly id!: string | null;

  @Prop({ required: false, type: [String, Array], default: () => '' })
  readonly exclude!: Bot['id'] | Bot['id'][];

  get bots(): Bot[] {
    return this.$store.state.bots.bots;
  }

  get filteredBots(): Bot[] {
    return Array.isArray(this.exclude) && this.exclude.length
      ? this.bots.filter((bot) => !this.exclude.includes(bot.id))
      : typeof this.exclude === 'string' && this.exclude
      ? this.bots.filter((bot) => bot.id !== this.exclude)
      : this.bots;
  }

  get sortedBots(): Bot[] {
    return this.filteredBots.slice().sort((a, b) => a.name.localeCompare(b.name));
  }

  get selectedBot(): Bot | undefined {
    return this.filteredBots.find((bot) => bot.id === this.value);
  }

  get selectOptions(): SelectOption[] {
    return this.sortedBots.map((bot) => ({
      label: bot.name,
      value: bot.id,
      disabled: false,
    }));
  }
}
