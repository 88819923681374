import store, { RootState } from '@/store';
import { NotificationData } from '@/types';
import { Module } from 'vuex';

export interface NotificationsModuleState {
  notifications: NotificationData[];
  notificationIdCount: number;
}

export const NotificationsModule: Module<NotificationsModuleState, RootState> = {
  namespaced: true,
  state: {
    notifications: [],
    notificationIdCount: 0,
  },
  mutations: {
    add(state, notification: NotificationData) {
      const idNotification = {
        ...notification,
        id: state.notificationIdCount++,
        duration: notification.duration ?? 4000,
      };

      if (idNotification.duration) {
        setTimeout(
          () => store.commit('notifications/remove', idNotification.id),
          idNotification.duration,
        );
      }
      state.notifications.push(idNotification);
    },
    remove(state, notificationId: string | number) {
      const index = state.notifications.findIndex(
        (notification) => notification.id === notificationId,
      );
      if (index >= 0 && index < state.notifications.length) {
        state.notifications.splice(index, 1);
      }
    },
    clear(state) {
      state.notifications = [];
    },
  },
};
