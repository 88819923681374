import { YextApi, YextCredentials } from '@/api';
import { RootState } from '@/store';
import { EntityMetaData, YextEntityData } from 'ignite360-core';
import { Module } from 'vuex';

export interface YextModuleState {
  entities: YextEntityData[];
}

const CREDENTIALS: YextCredentials = {
  accountId: '2320291',
  apiKey: process.env.VUE_APP_YEXT_API_KEY!,
  version: YextApi.prepareVersion(),
};

export const YextModule: Module<YextModuleState, RootState> = {
  namespaced: true,
  state: {
    entities: [],
  },
  getters: {},
  mutations: {
    setEntities(state, entities: YextEntityData[]) {
      state.entities = entities;
    },
  },
  actions: {
    async fetchAllEntities(context) {
      let entities = context.state.entities.slice();
      try {
        entities = await YextApi.loadAllEntities(CREDENTIALS, entities);
      } catch (e) {
        // TODO handle error
      }
      context.commit('setEntities', entities);
    },
    async fetchEntity(context, id: EntityMetaData['id']) {
      const entities = context.state.entities.slice();
      try {
        const entity = await YextApi.loadYextEntityData({ ...CREDENTIALS, entityId: id });
        const entityIndex = entities.findIndex((loadedEntity: YextEntityData) => {
          return loadedEntity.meta && loadedEntity.meta.id === id;
        });
        if (entityIndex >= 0) {
          entities.splice(entityIndex, 1, entity);
        } else {
          entities.push(entity);
        }
      } catch (e) {
        // TODO handle error
      }
      context.commit('setEntities', entities);
    },
  },
};
