import { BotApi } from '@/api';
import { RootState } from '@/store';
import { UpdateBotOptions, UpdateBotPlatformOptions, UpdateBotPlatformResult } from '@/types';
import { Bot, CreateBot, SupportedPlatform } from 'ignite360-core';
import { ActionContext, Module } from 'vuex';

export interface BotsModuleState {
  bots: Bot[];
}

export const BotsModule: Module<BotsModuleState, RootState> = {
  namespaced: true,
  state: {
    bots: [],
  },
  getters: {},
  mutations: {
    setBots(state, bots: Bot[]) {
      state.bots = bots;
    },
    addBot(state, bot: Bot) {
      const index = state.bots.findIndex((storedBot) => storedBot.id === bot.id);
      if (index >= 0) return;
      state.bots.push(bot);
    },
    updateBot(state, bot: Bot) {
      const index = state.bots.findIndex((storedBot) => storedBot.id === bot.id);
      if (index < 0) return;
      state.bots.splice(index, 1, bot);
    },
    updateBotPlatform<PLATFORM extends SupportedPlatform>(
      state: BotsModuleState,
      { id, platform, data }: UpdateBotPlatformResult<PLATFORM>,
    ) {
      const bot = state.bots.find((storedBot) => storedBot.id === id);
      if (!bot) return;
      bot.platformData[platform] = data;
    },
    deleteBot(state, id: Bot['id']) {
      const index = state.bots.findIndex((bot) => bot.id === id);
      if (index < 0) return;
      state.bots.splice(index, 1);
    },
  },
  actions: {
    async create(context, data: CreateBot) {
      const bot = await BotApi.createBot(data);
      context.commit('addBot', bot);
    },
    async fetchAll(context) {
      const bots = await BotApi.loadBots();
      context.commit('setBots', bots);
    },
    async update(context, { id, data }: UpdateBotOptions) {
      const bot = await BotApi.updateBot(id, data);
      context.commit('updateBot', bot);
    },
    async updatePlatform<PLATFORM extends SupportedPlatform>(
      context: ActionContext<BotsModuleState, RootState>,
      { id, platform, data }: UpdateBotPlatformOptions<PLATFORM>,
    ) {
      const platformData = await BotApi.updateBotPlatform(id, platform, data);
      const result: UpdateBotPlatformResult<PLATFORM> = {
        id,
        platform,
        data: platformData,
      };
      context.commit('updateBotPlatform', result);
    },
    async delete(context, id: Bot['id']) {
      const hasBot = context.state.bots.some((bot: Bot) => bot.id === id);
      if (!hasBot) return;
      await BotApi.deleteBot(id);
      context.commit('deleteBot', id);
    },
  },
};
