import { BASE_URL } from '@/api/index';
import axios, { AxiosRequestConfig } from 'axios';
import { LinkedInPage, LinkedInProfilePage } from 'ignite360-core';

export class LinkedInApi {
  static async loadLinkedInEmail(botId: string): Promise<string> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `${BASE_URL}/public/linked-in/profile/email?bot_id=${botId}`,
    };

    const result = await axios.request(config);

    if (result.status === 200 && result.data) {
      return result.data;
    }

    throw new Error('Something went wrong while trying to load your email address.');
  }

  static async loadLinkedInProfile(botId: string): Promise<LinkedInProfilePage> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `${BASE_URL}/public/linked-in/profile?bot_id=${botId}`,
    };

    const result = await axios.request(config);

    if (result.status === 200 && result.data) {
      return result.data as LinkedInProfilePage;
    }

    throw new Error('Something went wrong while trying to load your LinkedIn profile.');
  }

  static async loadOrganizations(botId: string): Promise<LinkedInPage[]> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `${BASE_URL}/public/linked-in/organizations?bot_id=${botId}`,
    };

    const res = await axios.request(config);

    if (res.status === 200) {
      return res.data as LinkedInPage[];
    }

    throw new Error('Something went wrong while loading organizations!');
  }

  static async publishTestPost(botId: string) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BASE_URL}/public/bots/${botId}/linked-in/publish-listing`,
    };

    const res = await axios.request(config);

    if (res.status !== 204) {
      throw new Error('Something went wrong while trying to post on LinkedIn.');
    }
  }

  static async connectBotWithLinkedInPage(botId: string, page: LinkedInPage): Promise<void> {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BASE_URL}/public/bots/${botId}/linked-in/subscribe`,
      data: { _id: page._id, type: page.type },
    };

    const res = await axios.request(config);

    if (res.status === 200) {
      return;
    }

    throw new Error(
      res.data.error || 'Something went wrong while trying to connect your LinkedIn Page.',
    );
  }

  static async disconnectBotFromLinkedInPage(botId: string): Promise<void> {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BASE_URL}/public/bots/${botId}/linked-in/unsubscribe`,
    };

    const res = await axios.request(config);

    if (res.status === 204) {
      return;
    }

    throw new Error(
      res.data.error || 'Something went wrong while trying to disconnect your LinkedIn Page.',
    );
  }
}
