
import { Api } from '@/api';
import { AppRoute } from '@/router/routes';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'reset-password',
})
export default class ResetPassword extends Vue {
  model = {
    code: '',
    password: '',
  };

  isLoading = false;

  mounted() {
    if (this.$route.query.code) {
      this.model.code = this.$route.query.code as string;
    }
  }

  async onSubmit() {
    if (!this.model.password || !this.model.code) {
      return;
    }

    this.isLoading = true;
    try {
      const user = await Api.resetPassword(this.model);
      this.$store.commit('auth/setUser', user.data);
      await this.$router.replace({ name: AppRoute.Projects });
      this.$notify.success('Password successfully changed.');
    } catch (e) {
      console.log(e.response);
      this.$notify.error({
        title: 'Password reset failed',
        description: e.response.data.details,
      });
    } finally {
      this.isLoading = false;
    }
  }
}
