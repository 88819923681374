
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'app-input-row',
})
export default class AppInputRow extends Vue {
  @Prop({ required: false, type: String, default: '' })
  readonly label!: string;

  @Prop({ required: false, type: String, default: () => null })
  readonly id!: string | null;

  @Prop({ required: false, type: Boolean, default: false })
  readonly required!: boolean;
}
