
import { SelectOption } from '@/types';
import { format, utcToZonedTime } from 'date-fns-tz';
import { findTimeZone, getUTCOffset, listTimeZones } from 'timezone-support';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'timezone-select',
})
export default class TimezoneSelect extends Vue {
  @Prop({ required: true, type: String })
  value!: string;

  get timezones(): SelectOption[] {
    const utcDate = new Date(new Date().toUTCString());
    return listTimeZones()
      .filter((timezone) => {
        return timezone.startsWith('Australia/') && !timezone.startsWith('Etc');
      })
      .sort((a, b) => {
        const infoA = findTimeZone(a);
        const infoB = findTimeZone(b);
        const offsetA = getUTCOffset(utcDate, infoA).offset;
        const offsetB = getUTCOffset(utcDate, infoB).offset;
        return offsetB - offsetA;
      })
      .map((timezone) => {
        const zonedDate = utcToZonedTime(utcDate, timezone);
        const label = timezone.replace(/[_]/g, ' ');
        return {
          value: timezone,
          label: `${label} (GMT ${format(zonedDate, 'xxx', { timeZone: timezone })})`,
        };
      });
  }
}
