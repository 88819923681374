import {Api, CHAINS_BASE_URL} from '@/api/index';
import axios from 'axios';
import {Chain, CreateChain, UpdateChain,} from 'ignite360-core';

export class ChainApi {

  static async createChain(data: CreateChain): Promise<Chain> {
    const config = Api.finalizeConfig({
      method: 'POST',
      url: CHAINS_BASE_URL,
      data,
    });

    const result = await axios.request<Chain>(config);
    if (result.status === 201 && result.data) {
      return result.data;
    }
    throw new Error('Could not create chain.');
  }

  static async loadChains(): Promise<Chain[]> {
    const config = Api.finalizeConfig({
      method: 'GET',
      url: CHAINS_BASE_URL,
    });

    const result = await axios.request<Chain[]>(config);

    if (result.status === 200 && result.data) {
      return result.data;
    }
    return [];
  }

  static async updateChain(id: string, data: UpdateChain): Promise<Chain> {
    const config = Api.finalizeConfig({
      method: 'PUT',
      url: `${CHAINS_BASE_URL}/${id}`,
      data,
    });

    const result = await axios.request<Chain>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not update chain.');
  }


  static async deleteChain(id: string): Promise<Chain> {
    const config = Api.finalizeConfig({
      method: 'DELETE',
      url: `${CHAINS_BASE_URL}/${id}`,
    });

    const result = await axios.request<Chain>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    throw new Error('Could not delete chain.');
  }
}
