
import BotForm from '@/components/bot/BotForm.vue';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotSettings } from '@/types';
import isEqual from 'fast-deep-equal/es6';
import { BotDomain, CreateBot, DEFAULT_TIMEZONE } from 'ignite360-core';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'bot-create-modal',
  components: { BotForm },
})
export default class BotCreateModal extends Vue {
  @Prop({ required: true, type: Boolean, default: false })
  readonly show!: boolean;

  isSubmitting = false;

  botSettings = this.resetValue();

  get settingsHaveChanged(): boolean {
    return !isEqual(this.botSettings, this.resetValue());
  }

  async onSubmit() {
    const data: CreateBot = {
      ...this.botSettings,
      selectedFeatures: Array.from(this.botSettings.selectedFeatures),
    };
    this.isSubmitting = true;
    try {
      await this.$store.dispatch('bots/create', data);
      this.$notify.success('Successfully created project');
      this.$emit('update:show', false);
    } catch (e) {
      this.$notify.error({
        title: 'Creating a project failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  resetValue(): BotSettings {
    return {
      name: '',
      tenantId: this.$tenantId,
      domain: BotDomain.Restaurant,
      selectedFeatures: new Set(),
      environment: 'production',
      timezone: DEFAULT_TIMEZONE,
    };
  }

  @Watch('show')
  reset() {
    this.botSettings = this.resetValue();
  }
}
