import { AuthModule, AuthModuleState } from '@/store/modules/auth';
import { BotsModule } from '@/store/modules/bots';
import { NotificationsModule } from '@/store/modules/notifications';
import { YextModule } from '@/store/modules/yext';
import Vue from 'vue';
import Vuex from 'vuex';
import { ChainsModule } from '@/store/modules/chains';
import VuexPersistence from 'vuex-persist';
import { InboxModule } from '@/store/modules/inbox';

Vue.use(Vuex);

export interface RootState {
  auth: AuthModuleState;
}

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
  modules: ['auth'],
});

export default new Vuex.Store<RootState>({
  mutations: {},
  actions: {},
  modules: {
    auth: AuthModule,
    bots: BotsModule,
    chains: ChainsModule,
    notifications: NotificationsModule,
    yext: YextModule,
    inbox: InboxModule,
  },
  plugins: [vuexLocal.plugin],
});
