import { RootState } from '@/store';
import { UpdateChainOptions } from '@/types';
import { Chain, CreateChain } from 'ignite360-core';
import { Module } from 'vuex';
import { ChainApi } from '@/api/ChainApi';

export interface ChainsModuleState {
  chains: Chain[];
}

export const ChainsModule: Module<ChainsModuleState, RootState> = {
  namespaced: true,
  state: {
    chains: [],
  },
  getters: {},
  mutations: {
    setChains(state, chains: Chain[]) {
      state.chains = chains;
    },
    addChain(state, chain: Chain) {
      const index = state.chains.findIndex((storedChain) => storedChain.id === chain.id);
      if (index >= 0) return;
      state.chains.push(chain);
    },
    updateChain(state, chain: Chain) {
      const index = state.chains.findIndex((storedChain) => storedChain.id === chain.id);
      if (index < 0) return;
      state.chains.splice(index, 1, chain);
    },
    deleteChain(state, id: Chain['id']) {
      const index = state.chains.findIndex((chain) => chain.id === id);
      if (index < 0) return;
      state.chains.splice(index, 1);
    },
  },

  actions: {
    async create(context, data: CreateChain) {
      const chain = await ChainApi.createChain(data);
      context.commit('addChain', chain);
    },
    async fetchAll(context) {
      const chains = await ChainApi.loadChains();
      context.commit('setChains', chains);
    },
    async update(context, { id, data }: UpdateChainOptions) {
      const chain = await ChainApi.updateChain(id, data);
      context.commit('updateChain', chain);
    },
    async delete(context, id: Chain['id']) {
      const hasChain = context.state.chains.some((chain: Chain) => chain.id === id);
      if (!hasChain) return;
      await ChainApi.deleteChain(id);
      context.commit('deleteChain', id);
    },
  },
};
