
import { Api } from '@/api';
import { AppRoute } from '@/router/routes';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'login',
})
export default class Login extends Vue {
  model = {
    email: '',
    password: '',
  };

  isLoading = false;

  async onSubmit() {
    if (!this.model.email || !this.model.password) {
      return;
    }

    this.isLoading = true;
    try {
      const user = await Api.login(this.model);
      this.$notify.success('Successfully logged in');
      this.$store.commit('auth/setUser', user.data);
      await this.$router.replace({ name: AppRoute.Projects });
    } catch (e) {
      console.log(e);
      this.$notify.error({
        title: 'Login failed',
        description: 'Invalid credentials',
      });
    } finally {
      this.isLoading = false;
    }
  }
}
