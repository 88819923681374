
import BotFeaturesCheckboxGroup from '@/components/bot/BotFeaturesCheckboxGroup.vue';
import { BotSettings, SelectOption } from '@/types';
import { BotDomain } from 'ignite360-core';
import { Component, Prop, Vue } from 'vue-property-decorator';
import InviteCollaboratorModal from '@/components/bot/InviteCollaboratorModal.vue';
import { AuthMixin } from '@/mixins/AuthMixin';
import { mixins } from 'vue-class-component';

@Component({
  name: 'bot-form',
  components: { InviteCollaboratorModal, BotFeaturesCheckboxGroup },
})
export default class BotForm extends mixins(AuthMixin) {
  @Prop({ required: true, type: Object })
  readonly value!: BotSettings;

  showInviteCollaboratorModal = false;

  onInput(key: keyof BotSettings, value: any) {
    this.$emit('input', { ...this.value, [key]: value });
  }

  get domainSelectOptions(): SelectOption[] {
    return [
      { value: BotDomain.Restaurant, label: 'Restaurant', disabled: false },
      { value: BotDomain.RealEstate, label: 'Real Estate', disabled: false },
    ];
  }

  get environmentSelectOptions(): SelectOption[] {
    return [
      {
        value: 'staging',
        label: 'Staging',
      },
      {
        value: 'production',
        label: 'Production',
      },
    ];
  }
}
