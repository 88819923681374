
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';

@Component({
  name: 'default-sidebar-link',
})
export default class DefaultSidebarLink extends Vue {
  @Prop({ required: true, type: [String, Object] })
  readonly to!: RawLocation;

  @Prop({ required: false, type: Boolean, default: false })
  readonly exact!: boolean;
}
