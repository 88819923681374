
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'app-checkbox',
})
export default class AppCheckbox extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly disabled!: boolean;
}
