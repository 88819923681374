
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'app-toggle',
})
export default class AppToggle extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value!: boolean;
}
