
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'app-form-card',
})
export default class AppFormCard extends Vue {
  @Prop({ required: false, type: String, default: '' })
  readonly header!: string;

  @Prop({ required: false, type: [Array, String], default: () => [] })
  readonly headerClass!: string | string[];

  @Prop({ required: false, type: [Array, String], default: () => [] })
  readonly bodyClass!: string | string[];

  @Prop({ required: false, type: [Array, String], default: () => [] })
  readonly footerClass!: string | string[];

  @Prop({ required: false, type: Boolean, default: false })
  readonly scrollable!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly saveButtonDisabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly saveButtonLoading!: boolean;

  @Prop({ required: false, type: String, default: 'Save' })
  readonly saveButtonText!: string;

  onSubmit() {
    if (this.saveButtonDisabled) return;
    this.$emit('submit');
  }

  get mergedBodyClass() {
    return [
      'space-y-6',
      ...(typeof this.bodyClass === 'string' ? [this.bodyClass] : this.bodyClass),
    ];
  }

  get mergedFooterClass() {
    return [
      'text-right bg-gray-50',
      ...(typeof this.footerClass === 'string' ? [this.footerClass] : this.footerClass),
    ];
  }
}
