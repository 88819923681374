
import DefaultNavbar from '@/components/default-layout/DefaultNavbar.vue';
import DefaultSidebar from '@/components/default-layout/DefaultSidebar.vue';
import DefaultSidebarDesktop from '@/components/default-layout/DefaultSidebarDesktop.vue';
import DefaultSidebarMobile from '@/components/default-layout/DefaultSidebarMobile.vue';
import NotificationContainer from '@/components/ui/NotificationContainer.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'default-layout',
  components: {
    NotificationContainer,
    DefaultSidebarMobile,
    DefaultSidebarDesktop,
    DefaultSidebar,
    DefaultNavbar,
  },
})
export default class DefaultLayout extends Vue {
  showMobileSidebar = false;
}
